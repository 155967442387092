import React, { Suspense } from 'react';
import LoadPage from '../components/LoadPage'
import MetaTags from 'react-meta-tags';
import '../scss/Home.scss';

const Slider = React.lazy(() => import('../components/Slider'));
const HomeContent = React.lazy(() => import('./content/HomeContent'));
const ProjetsStar = React.lazy(() => import('./content/ProjetsStar'));
const Temoignages = React.lazy(() => import('./content/Temoignages'));
const Icon = React.lazy(() => import('../assets/icons'));
const LesMavericks = React.lazy(() => import('./content/LesMavericks'));

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.homeScroll = null;
    this.onScroll = this.onScroll.bind(this);
    this.handlePageLoad = this.handlePageLoad.bind(this);
    this.isVisible = this.isVisible.bind(this);
    this.mySectionClient = React.createRef()  
    this.mySectionBottom = React.createRef()  
    this.mySectionMavericks = React.createRef()  
    this.refTopPage = React.createRef()
    this.refHomeContent = React.createRef()
    this.ticked = false
    this.state = {
      theme_menu: "light",
      load: false,
      show_bottom_element: false
    };
  }
  
  scrollToRef(ref){
    if(ref && ref.current){
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }
  scrollToRefTop(){
    window.scrollTo({
      behavior: "smooth",
      top: this.refTopPage.current.offsetTop - 65
    })  
  }
  
  handlePageLoad(loaded){
    this.setState({
      load: loaded
    })
  }

  componentDidMount(){
    if(document.getElementById('navbarResponsive').classList.contains('show')) {
        document.getElementById('navbarResponsive').classList.remove('show')
        document.body.classList.remove('no-scroll')
    }
    this.onScroll()
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.homeScroll)
  }

  onScroll() {
    var _this = this
    if(_this.refHomeContent.current && this.isVisible(_this.refHomeContent.current)){
      _this.setState({
        show_bottom_element: true
      })
    } else {
      if(!_this.ticked){
        this.homeScroll = window.addEventListener('scroll', function(e) {
          if(_this.isVisible(_this.refHomeContent.current)){
            _this.setState({
              show_bottom_element: true
            })
            _this.ticked = true
          }
        });
      }
    }
  }

  isVisible(elt){
    if(!elt){
      return false
    }
    let bounding = elt.getBoundingClientRect()
    if (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    ) {
      return true
    } else {
      return false
    }
  }

  render() {
    return (
        <div className="page-home bg-dark-light" ref={this.refTopPage}>
          <MetaTags>
              <title>Maverick Paris - Stratégie Web Marketing et Data</title>
              <link rel="canonical" href="https://maverick.paris/" />
              <meta name="description" content="Les experts de Maverick Paris sont spécialisés dans les métiers du Web, du Marketing et de la Data." />
              
              <meta property="og:type" content="website"/>
              <meta property="og:url" content="https://maverick.paris/" />
              <meta property="og:title" content="Maverick Paris - Stratégie Web Marketing et Data" />
              <meta property="og:image" content="https://cdn.maverick.paris/themes/maverick/1200x630-black.png" />
              <meta property="og:description" content="Les experts de Maverick Paris sont spécialisés dans les métiers du Web, du Marketing et de la Data." />

              <meta property="twitter:card" content="summary_large_image"/>
              <meta property="twitter:url" content="https://maverick.paris/"/>
              <meta property="twitter:title" content="Maverick Paris - Stratégie Web Marketing et Data"/>
              <meta property="twitter:image" content="https://cdn.maverick.paris/themes/maverick/1200x630-black.png"/>
              <meta property="twitter:description" content="Les experts de Maverick Paris sont spécialisés dans les métiers du Web, du Marketing et de la Data."/>
          </MetaTags>
              <LoadPage load={this.state.load} notreset={false}/>
              <Suspense fallback={<div></div>}>
                <Slider onPageLoad={this.handlePageLoad}/>
                <HomeContent/>
              </Suspense>
              {this.state.load && (
                <div ref={this.refHomeContent}>
                {this.state.show_bottom_element && (
                  <>
                    <Suspense fallback={<div></div>}>
                      <div className="arrow bottom-arrow" onClick={this.scrollToRef.bind(this, this.mySectionClient)}><Icon name="left-arrow" width={15} height={25} fill='#DF01FF' /></div>
                      <div className="vertical-list" ref={this.mySectionClient}><ProjetsStar/></div>
                      <div className="arrow bottom-arrow" onClick={this.scrollToRef.bind(this, this.mySectionBottom)}><Icon name="left-arrow" width={15} height={25} fill='#DF01FF' /></div>
                      <div className="section-temoignages" ref={this.mySectionBottom}><Temoignages /></div>
                      <div className="arrow bottom-arrow" onClick={this.scrollToRef.bind(this, this.mySectionMavericks)}><Icon name="left-arrow" width={15} height={25} fill='#DF01FF' /></div>
                      <div className="section-teams bg-dark mt-5" ref={this.mySectionMavericks}><LesMavericks /></div>
                      <div className="bg-dark"><div className="arrow top-arrow" onClick={this.scrollToRef.bind(this, this.refTopPage)}><Icon name="left-arrow" width={15} height={25} fill='#DF01FF' /></div></div>
                    </Suspense>
                  </>
                )}
                </div>
              )}
          </div>
    );
  }
}

export default Home;
