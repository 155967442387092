import React from 'react';
import './App.scss'
import { NavLink, Link } from "react-router-dom";
import Main from './Main'
import MetaTags from 'react-meta-tags';
import Logo from './logo-maverick.png'
import FooterLine from './components/Footer'
import BannerCookie from './components/BannerCookie'
import Cookies from 'js-cookie';
import { CSSTransition } from 'react-transition-group'
import ContactIcon from './components/ContactIcon'
import InstallPWA from './components/InstallPWA'
import Headroom from 'react-headroom'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-5S6KSRV'
}

class App extends React.Component {
  constructor(props) {
    super(props);
    
    this.handleScript = this.handleScript.bind(this);
    this.handleCloseConfig = this.handleCloseConfig.bind(this);
    this.openConfigCookie = this.openConfigCookie.bind(this);
    this.onUnpin = this.onUnpin.bind(this)
    this.onPin = this.onPin.bind(this)
    this.refs = {}
    this.setRef = (element) => {
      this.refs[element.id] = element
    }
    this.collapseRef = (id) => {
      if (this.refs){
        document.body.classList.toggle('no-scroll')
        this.refs[id].classList.toggle('show')
      } 
    }
    var showcookie = Cookies.get('banner-cookie_compliance') ? Cookies.get('banner-cookie_compliance') : false
    this.robot = ""
    if(/bot|googlebot|crawler|spider|robot|crawling|Chrome-Lighthouse/i.test(navigator.userAgent)){
      showcookie = true
      this.robot=" robot"
    }
    TagManager.initialize(tagManagerArgs)
    
    this.state = {
      theme_menu: "light",
      showcookiebanner: showcookie,
      script_append:[],
      showConfigCookie: false
    };
  }

  handleScript(scripts){
    var scripts_append = []
    var pushDatalayer = ",strict"
    scripts_append = scripts
    for (var i = 0; i < scripts.length; i++) {
      var script_data = scripts[i]
      if(script_data !== null && script_data.datalayer !== null)
        pushDatalayer+=script_data.datalayer
    } 

    if(pushDatalayer){
      window.dataLayer.push({event:'cookie_compliance', 'cookie_compliance': pushDatalayer})  
    }

    this.setState({
      script_append: scripts_append,
      showcookiebanner: true
    })
    Cookies.set('banner-cookie_compliance', true)
  }

  handleCloseConfig(){
    document.body.classList.remove('no-scroll')
    this.setState({
      showConfigCookie: false
    })
  }

  openConfigCookie(){
    this.setState({
      showConfigCookie: true
    })
  }

  onUnpin(){
    var eltClassFilterMobile = document.getElementById('sitckyFilter')
    var eltClassFilte = document.getElementById('stickyLg')
    if(eltClassFilterMobile && !eltClassFilterMobile.classList.contains('unpin-menu')){
      eltClassFilterMobile.classList.add('unpin-menu')
    }
    if(eltClassFilte && !eltClassFilte.classList.contains('unpin-menu')){
      eltClassFilte.classList.add('unpin-menu')
    }  
  }

  onPin(){
    var eltClass = document.getElementById('sitckyFilter')
    var eltClassFilte = document.getElementById('stickyLg')
    if(eltClass && eltClass.classList.contains('unpin-menu')){
      eltClass.classList.remove('unpin-menu')
    }
    if(eltClassFilte && eltClassFilte.classList.contains('unpin-menu')){
      eltClassFilte.classList.remove('unpin-menu')
    }
  }

  render() {
    let cookie_banner_install = Cookies.get("pwa_install") ? Cookies.get("pwa_install") : false
    return (
      <div className="App">
        <MetaTags>
            <title>Maverick Paris - Stratégie Web Marketing et Data</title>
            <link rel="canonical" href="https://maverick.paris/" />
            <meta name="description" content="Les experts de Maverick Paris sont spécialisés dans les métiers du Web, du Marketing et de la Data." />

            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://maverick.paris/" />
            <meta property="og:title" content="Maverick Paris - Stratégie Web Marketing et Data" />
            <meta property="og:image" content="https://cdn.maverick.paris/themes/maverick/1200x630-black.png" />
            <meta property="og:description" content="Les experts de Maverick Paris sont spécialisés dans les métiers du Web, du Marketing et de la Data." />

            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content="https://maverick.paris/"/>
            <meta property="twitter:title" content="Maverick Paris - Stratégie Web Marketing et Data"/>
            <meta property="twitter:image" content="https://cdn.maverick.paris/themes/maverick/1200x630-black.png"/>
            <meta property="twitter:description" content="Les experts de Maverick Paris sont spécialisés dans les métiers du Web, du Marketing et de la Data."/>
          </MetaTags>
          <Headroom upTolerance={30} onUnpin={this.onUnpin} onPin={this.onPin}>
          <header className="header">
            <nav id="header-menu" className="navbar navbar-expand-lg navbar-dark bg-dark static-top">
              <div className="container">
                <Link to="/" className="navbar-brand"><img className="logo" src={Logo} alt="Maverick Paris" /></Link>
                <button onClick={() => this.collapseRef('navbarResponsive')} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                <div className="collapse navbar-collapse" id="navbarResponsive" ref="navbarResponsive">
                  <button onClick={() => this.collapseRef('navbarResponsive')} className="navbar-toggler d-block d-md-none closed-btn" type="button" data-toggle="collapse" data-target="#navbarResponsive">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <NavLink to="/" exact className="nav-link" activeClassName="active">Accueil</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/projets" className="nav-link" activeClassName="active">Nos projets</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/services" className="nav-link" activeClassName="active">Nos services</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/articles" className="nav-link" activeClassName="active">Articles</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/contact" className="nav-link" activeClassName="active">Contact</NavLink>
                    </li>
                  </ul>
                  <div className="d-block d-md-none menu-info-more text-center">
                    <div className="trait"></div>
                    <p>Expertise&nbsp;&&nbsp;Audace<br/>Webmarketing - Stratégie&nbsp;Digitale - Développement&nbsp;Web - Motion&nbsp;Design<br/>
                    <strong>Paris et Océan Indien</strong></p>
                    <ContactIcon menu={true}/>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        </Headroom>
        <div className={"main-wrapper bg-dark-light" + this.robot}><Main/></div>
        <footer className="bg-dark" id="footer">
          <div className="menu-footer pt-4 navbar navbar-expand-lg">
            <ul className="navbar-nav m-auto">
              <li><NavLink to="/mentions-legales" className="nav-link" activeClassName="active">Mentions légales</NavLink></li>
              <li><NavLink to="/politique-de-confidentialite" className="nav-link" activeClassName="active">Politique de confidentialité</NavLink></li>
              <li><Link to="#" className="nav-link" onClick={this.openConfigCookie}>Préférences cookies</Link></li>
            </ul>
          </div>
          <FooterLine />
        </footer>
        <CSSTransition timeout={2000} classNames="top-slide" appear={!this.state.showcookiebanner} in={!this.state.showcookiebanner}><BannerCookie onHandleScript={this.handleScript} showBanner={this.state.showcookiebanner} showPreference={this.state.showConfigCookie} handleCloseConfig={this.handleCloseConfig}/></CSSTransition>
        {this.state.showcookiebanner ? <InstallPWA panel="page" banner_cookie={cookie_banner_install}></InstallPWA> : null}
      </div>
    );
  }
}

export default App;
