import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 33.61 33.61"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
        fill={fill}
        d="M16.81,33.61a16.81,16.81,0,1,1,16.8-16.8A16.83,16.83,0,0,1,16.81,33.61Zm0-32.15A15.35,15.35,0,1,0,32.15,16.81,15.36,15.36,0,0,0,16.81,1.462"
    />
    <path
      fill={fill}
      d="M21.24,25.35a9.08,9.08,0,0,1-3.91-1.13c-3.7-2-6.72-4.75-8.36-8.75a14.68,14.68,0,0,1-.8-2.92,3.41,3.41,0,0,1,1-3.09,8.76,8.76,0,0,1,1.67-1.29c.69-.43,1.08-.3,1.5.38.62,1,1.24,2,1.85,3,.32.52.3.76-.14,1.19s-.94.84-1.42,1.24a.9.9,0,0,0-.29,1.27,13.47,13.47,0,0,0,1.32,2,22,22,0,0,0,4.51,3.77c.64.41.86.37,1.35-.21.35-.42.67-.86,1-1.27s.72-.58,1.33-.22C23,20,24,20.61,25.07,21.2a.72.72,0,0,1,.38,1,4.39,4.39,0,0,1-3.4,3.08C21.75,25.31,21.44,25.32,21.24,25.35Z"
    />
  </svg>
);

export default SVG;
//<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.61 33.61"><title>ico_phone</title><g id="55b3f951-8281-4905-89fe-4a011bb41c6e" data-name="Calque 2"><g id="228841dc-260b-4425-8760-b098841f6baa" data-name="Calque 1"><path d="M16.81,33.61a16.81,16.81,0,1,1,16.8-16.8A16.83,16.83,0,0,1,16.81,33.61Zm0-32.15A15.35,15.35,0,1,0,32.15,16.81,15.36,15.36,0,0,0,16.81,1.46Z"/><path d="M21.24,25.35a9.08,9.08,0,0,1-3.91-1.13c-3.7-2-6.72-4.75-8.36-8.75a14.68,14.68,0,0,1-.8-2.92,3.41,3.41,0,0,1,1-3.09,8.76,8.76,0,0,1,1.67-1.29c.69-.43,1.08-.3,1.5.38.62,1,1.24,2,1.85,3,.32.52.3.76-.14,1.19s-.94.84-1.42,1.24a.9.9,0,0,0-.29,1.27,13.47,13.47,0,0,0,1.32,2,22,22,0,0,0,4.51,3.77c.64.41.86.37,1.35-.21.35-.42.67-.86,1-1.27s.72-.58,1.33-.22C23,20,24,20.61,25.07,21.2a.72.72,0,0,1,.38,1,4.39,4.39,0,0,1-3.4,3.08C21.75,25.31,21.44,25.32,21.24,25.35Z"/></g></g></svg>