
import React from 'react'

class Footer extends React.Component {
    render() {
        
        return <div className="footer container">
            <div className="row justify-content-center">
                <p>Maverick • <a href="https://goo.gl/maps/fNFHBqzz42AicmLB9" target="_blank" rel="noopener noreferrer">198 Avenue de France – 75013 Paris</a><br/><small>Copyright © Maverick.Paris 2020</small></p>
            </div>
        </div>
    }
}

export default Footer;