import React from 'react'
import Cookies from 'js-cookie';

class PreferenceCookie extends React.Component {
    // Using ES6 class syntax
    constructor(props) {
       super(props);
       this.savePreference = this.savePreference.bind(this);
       this.state = {
           cookies: this.props.cookies
       }
       this.closedPreference = React.createRef();
    }

    savePreference(){
        this.props.onHandleSave(this.state.cookies)
        this.closedPreference.current.click();
    }

    handleToggleComplete(id){
        const newList = this.state.cookies.map((item) => {
            if (item.id === id) {
              const updatedItem = {
                ...item,
                enable: !item.enable,
              };
       
              return updatedItem;
            }
       
            return item;
        });
        this.setState({
            cookies: newList
        })
    }

    componentWillMount(){
        document.body.classList.add('no-scroll')
        const newList = this.state.cookies.map((item) => {
            if (Cookies.get(item.id) && Cookies.get(item.id) === "true") {
              const updatedItem = {
                ...item,
                enable: true,
              };
       
              return updatedItem;
            }
       
            return item;
        });
        this.setState({
            cookies: newList
        })
    }

    componentWillUnmount(){
        document.body.classList.remove('no-scroll')
    }
     
    render() {
        return <div className="cookie-preference">
                    <div className="fade-elt"></div>
                    <div className="cookie-preference-container container">
                        <section className="row justify-content-center closed-preference" onClick={this.props.onHandler}>
                            <div className="col-12 col-md-6 bg-secondary all-preference-cookie">
                                <div className="header-cookie-preference">
                                    Votre confidentialité
                                    <div className="closed-preference" onClick={this.props.onHandler}>Fermer</div>
                                </div>
                                <div className="preferences-all">
                                    <div className="text-left description-cookies">
                                        <p>Ce site utilise des cookies fonctionnels et des scripts externes pour améliorer votre expérience. Les cookies et les scripts utilisés et leur impact sur votre visite sont spécifiés ci-dessous. Vous pouvez modifier vos paramètres à tout moment.
                                            Pour en savoir plus, voir notre <a href="/politique-de-confidentialite" target="_blank">Politique de confidentialité</a></p>
                                        <p><strong>REMARQUE:</strong> Ces paramètres ne s’appliqueront qu’au navigateur et à l’appareil que vous utilisez actuellement.</p>
                                    </div>
                                    <div className="content-cookie-preference text-left">
                                    {
                                        this.state.cookies.map((cookie, i) => {
                                            return <div className="item-cookie" key={cookie.id}>
                                                <h4>{cookie.name}</h4>
                                                <div className="desc-cookie" dangerouslySetInnerHTML={{__html: cookie.description}} />
                                                {cookie.required ? <div className=""><button type="button" className="switch-btn checked" aria-checked="true" role="switch"><div></div></button> Requis</div> : <div className=""><button type="button" aria-checked={cookie.enable ? true : false} className={cookie.enable ? "switch-btn checked" : "switch-btn"} role="switch" onClick={() => this.handleToggleComplete(cookie.id)}><div></div></button> Activer</div>}
                                            </div>
                                        })
                                    }
                                    </div>
                                </div>
                                <div className="footer-cookie-preference text-center">
                                    <div className="btn btn-secondary" onClick={this.savePreference}>Enregistrer mes paramètres</div>
                                    <div className="btn btn-primary" onClick={this.props.onHandlerAcceptAll}>Tout accepter</div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
    }
}

export default PreferenceCookie;