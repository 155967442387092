import React from "react";

const SVG = ({
  style = {},
  fill = "#FFF",
  fillScd = "#2A3138",
  stroke = "#DF01FF",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 32 32"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect fill={fill} y="13" width="32" height="2"/>
    <rect fill={fill} y="4" width="32" height="2"/>
    <rect fill={fill} y="22" width="32" height="2"/>
    <circle stroke={stroke} strokeWidth="3" fill={fillScd} cx="8" cy="14" r="4"/>
    <circle stroke={stroke} strokeWidth="3" fill={fillScd} cx="25" cy="5" r="4"/>
    <circle stroke={stroke} strokeWidth="3" fill={fillScd} cx="20" cy="23" r="4"/>
  </svg>
);
/*const SVG = ({
  style = {
    "fill": "#FFF",
    "height": "25px",
    "width": "25px",
    "stroke-width": "4",
    "overflow": "visible"
  },
  fill = "#FFF",
  fillScd = "#DF01FF",
  width = "100%",
  height = "16px",
  className = "",
  viewBox = "0 0 37.2 27"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
<rect fill={fill} x="5.5" y="12.5" transform="matrix(6.123234e-17 -1 1 6.123234e-17 5.5 32.5)" width="27" height="2"/>
<rect fill={fill} x="-3.5" y="12.5" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -3.5 23.5)" width="27" height="2"/>
<rect fill={fill} x="14.5" y="12.5" transform="matrix(6.123234e-17 -1 1 6.123234e-17 14.5 41.5)" width="27" height="2"/>
<circle stroke={fillScd} cx="28" cy="10" r="3.5"/>
<circle stroke={fillScd} cx="10" cy="6" r="3.5"/>
<circle stroke={fillScd} cx="19" cy="20" r="3.5"/>
  </svg>
);*/
export default SVG;

//<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.61 33.61"><title>ico_linkedin</title><g id="429aa40b-658d-4eae-993f-01b77ba80e76" data-name="Calque 2"><g id="0db13096-047a-4b55-8be5-125bf3a20d0b" data-name="Calque 1"><path d="M25.51,18.06v6.15a.29.29,0,0,1-.28.28H22.06a.28.28,0,0,1-.28-.28V18.49c0-1.5-.54-2.53-1.89-2.53A2,2,0,0,0,18,17.32a2.51,2.51,0,0,0-.12.91v6a.28.28,0,0,1-.28.28H14.41a.29.29,0,0,1-.28-.28c0-1.52,0-8.91,0-10.65a.28.28,0,0,1,.28-.28h3.16a.27.27,0,0,1,.28.28v1.31l0,0h0v0A3.7,3.7,0,0,1,21.22,13c2.45,0,4.29,1.6,4.29,5ZM8.62,24.49H11.8a.28.28,0,0,0,.27-.28V13.56a.27.27,0,0,0-.27-.28H8.62a.27.27,0,0,0-.27.28V24.21A.28.28,0,0,0,8.62,24.49Z"/><circle cx="10.1" cy="9.86" r="1.99"/><path d="M16.81,33.61a16.81,16.81,0,1,1,16.8-16.8A16.83,16.83,0,0,1,16.81,33.61Zm0-32.15A15.35,15.35,0,1,0,32.15,16.81,15.36,15.36,0,0,0,16.81,1.46Z"/></g></g></svg>