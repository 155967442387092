import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './pages/Home'

const Articles = lazy(() => import('./pages/Articles'));
const Projets = lazy(() => import('./pages/Projets'));
const Services = lazy(() => import('./pages/Services'));
const DetailArticle = lazy(() => import('./pages/DetailArticle'));
const Contact = lazy(() => import('./pages/Contact'));
const MentionsLegales = lazy(() => import('./pages/MentionsLegales'));
const Politique = lazy(() => import('./pages/Politique'));
const Projet = lazy(() => import('./pages/Projet'));
const NotFoundPage = lazy(() => import('./pages/NotFound'));

const Main = () => {
  return (
    <Suspense fallback={<div></div>}>
      <Switch> {/* The Switch decides which component to show based on the current URL.*/}
          <Route exact path='/' component={Home}></Route>
          <Route exact path='/services' component={Services}></Route>
          <Route exact path='/projets' component={Projets}></Route>
          <Route exact path='/projets/page/:page' component={Projets}></Route>
          <Route exact path='/projets/:path_alias' component={Projet}></Route>
          <Route exact path='/articles' component={Articles}></Route>
          <Route exact path="/articles/tendances-seo-2021">
            <Redirect to="/articles/tendances-seo" />
          </Route>
          <Route exact path="/articles/sxo-fer-de-lance-strategie-2021">
            <Redirect to="/articles/sxo-fer-de-lance-strategie-web" />
          </Route>
          <Route exact path="/articles/guide-generation-leads-2021">
            <Redirect to="/articles/guide-generation-leads" />
          </Route>
          <Route exact path='/articles/page/:page' component={Articles}></Route>
          <Route exact path='/articles/:path_alias' component={DetailArticle}></Route>
          <Route exact path='/contact' component={Contact}></Route>
          <Route exact path='/mentions-legales' component={MentionsLegales}></Route>
          <Route exact path='/politique-de-confidentialite' component={Politique}></Route>
          <Redirect from='/projet/*' to="/projets/*" />
          <Redirect from='/article/*' to="/articles/*" />
          <Route path='*' component={NotFoundPage}></Route>
      </Switch>
    </Suspense>
  );
}

export default Main;