
import React from 'react'
import PreferenceCookie from './PreferenceCookie'
import Cookies from 'js-cookie';
import { CSSTransition } from 'react-transition-group';

class BannerCookie extends React.Component {
    // Using ES6 class syntax
    constructor(props) {
       super(props);
       this.acceptAll = this.acceptAll.bind(this);
       this.rejectAll = this.rejectAll.bind(this);
       this.showModalPreference = this.showModalPreference.bind(this);
       this.resetCookieBanner = this.resetCookieBanner.bind(this);
       this.savePreferenceUser = this.savePreferenceUser.bind(this);
       this.state = {
        showpreference:false
       }

       this.cookies = [
            {
                id: "1-cookie",
                name: "1 - Cookies strictement nécessaires",
                description: "<p>Ces cookies sont nécessaires au fonctionnement du site Web et ne peuvent pas être désactivés dans nos systèmes.</p><p>Ils sont généralement établis en tant que réponse à des actions que vous avez effectuées et qui constituent une demande de services, telles que la définition de vos préférences en matière de confidentialité, la connexion ou le remplissage de formulaires.</p><p>Vous pouvez configurer votre navigateur afin de bloquer ou être informé de l’existence de ces cookies, mais certaines parties du site Web peuvent être affectées. Ces cookies ne stockent aucune information d’identification personnelle.</p>",
                required: true,
                accept: true,
                enable: true,
                script:null,
                datalayer:null
            },
            {
                id: "2-cookie",
                name: "2 - Cookies de performance",
                description: "<p>Ces cookies nous permettent de déterminer le nombre de visites et les sources du trafic, afin de mesurer et d’améliorer les performances de notre site Web.<br>Ils nous aident également à identifier les pages les plus / moins visitées et d’évaluer comment les visiteurs naviguent sur le site Web.</p><p>Toutes les informations collectées par ces cookies sont agrégées et donc anonymisées. Si vous n’acceptez pas ces cookies, nous ne serons pas informé de votre visite sur notre site.</p>",
                required: false,
                accept: true,
                enable: false,
                script: "<!-- Google Tag Manager --><script>console.log('gt');</script><!-- End Google Tag Manager -->",
                datalayer: ",perf"
            },
            {
                id: "3-cookie--target",
                name: "3 - Cookies de publiité ciblés et réseaux sociaux",
                description: "<p><u>Réseaux sociaux</u> : Ces cookies permettent d’interagir depuis le site Maverick.paris avec différents réseaux sociaux et notamment : - partager nos contenus avec votre communauté  - informer votre communauté sur ce que vous avez consulté ou apprécié sur le site Maverick.paris. En désactivant ces cookies, vous ne pourrez plus partager les contenus présents sur le site Maverick.paris directement à votre communauté. Ces cookies, associés aux données de navigation du site Maverick.paris, nous permettent de créer et de diffuser certaines publicités personnalisées sur les différents réseaux sociaux, tels que Twitter, Facebook, Instagram, LinkedIn et Pinterest. En désactivant ces cookies, Maverick Paris ne pourra plus vous proposer ce type de publicités personnalisées.</p><p><u>Publicités ciblées</u> : Ces cookies permettent à Maverick.paris  de vous adresser des publicités pertinentes en fonction de  vos centres d’intérêt, basé sur votre navigation sur notre site. En désactivant ces cookies, cela aura pour effet de vous voir proposer des publicités moins pertinentes pour vous.</p>",
                required: false,
                accept: true,
                enable: false,
                script: "<!-- Google Tag Manager --><script>console.log('target');</script><!-- End Google Tag Manager -->",
                datalayer: ",target_social"
            }
        ]
    }

    acceptAll(){
        var script = this.cookies.map((item) => {
            Cookies.set(item.id, true)
            return {id:item.id, html:item.script, datalayer:item.datalayer}
        })
        this.props.onHandleScript(script)
    }

    rejectAll(){
        var script = this.cookies.map((item) => {
            var bCheck = false
            if(item.datalayer === null){
                Cookies.set(item.id, bCheck)
                return {id:item.id, html:item.script, datalayer:item.datalayer}
            } else {
                return {id: null, html: null, datalayer:null}
            }
        })
        this.props.onHandleScript(script)
    }

    showModalPreference(){
        var showpreference = this.state.showpreference ? false : true
        this.setState({
            showpreference: showpreference
        })
    }

    resetCookieBanner(e){
        if (!e.target.className.includes('closed-preference'))
            return;
        this.setState({
            showpreference: false
        })
        this.props.handleCloseConfig()
    }

    savePreferenceUser(cookies){
        var script = cookies.map((item) => {
            if(item.enable){
                Cookies.set(item.id, true)
                return {id:item.id, html:item.script}
            } else {
                Cookies.set(item.id, false)
                return {id: null, html: null}
            }
        })
        this.props.onHandleScript(script)
    }

    componentDidMount(){
        if (this.props.showBanner) {
            var script = this.cookies.map(item => {
                if(Cookies.get(item.id) === "true" && item.script !== null){
                    return {id: item.id, html: item.script}
                }
                return null
            })
            this.props.onHandleScript(script)
        }
    }
     
    render() {
        return <div>
                    {!this.state.showpreference && !this.props.showBanner ? <div>
                        <div className="fade-banner-cookie"></div>
                        <div className="cookie-consent" role="banner" aria-hidden={this.state.showpreference ? 'true' : 'false' }>
                            <div className="cookie-consent-container">
                                <section className="row text-left">
                                    <div className="content-text col-12">
                                        <h3>Votre vie privée</h3>
                                        <p>Nous utilisons des cookies et d'autres technologies similaires afin de personnaliser notre contenu, ainsi que proposer une meilleure expérience. En cliquant sur OK ou en activant une option dans Préférences de cookies, vous acceptez les conditions énoncées dans notre Politique en matière de cookies. Pour modifier vos préférences ou retirer votre consentement, vous devez mettre à jour vos Préférences de cookies.</p>
                                    </div>
                                    <div className="content-button col-12">
                                        <button type="button" className="preference-btn btn btn-secondary" onClick={this.showModalPreference}>Préférences de cookies</button>
                                        <button type="button" className="accept-btn btn btn-primary" onClick={this.acceptAll}>Accepter</button>
                                        <div className="reject-btn text-primary btn" onClick={this.rejectAll}><span>Rejeter</span></div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div> : null }
                    {((this.state.showpreference && !this.props.showBanner) || this.props.showPreference ) ? <CSSTransition timeout={0} classNames="top-slide" appear={(this.state.showpreference || this.props.showPreference)} in={(this.state.showpreference || this.props.showPreference)}><PreferenceCookie onHandler={this.resetCookieBanner} onHandleSave={this.savePreferenceUser} onHandlerAcceptAll={this.acceptAll} cookies={this.cookies}/></CSSTransition> : null }
                </div>
    }
}

export default BannerCookie;