import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 33.61 33.61"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
        fill={fill}
        d="M16.81,33.61a16.81,16.81,0,1,1,16.8-16.8A16.83,16.83,0,0,1,16.81,33.61Zm0-32.15A15.35,15.35,0,1,0,32.15,16.81,15.36,15.36,0,0,0,16.81,1.46Z"
    />
    <path
      fill={fill}
      d="M23.84,9.94H9.77a2.25,2.25,0,0,0-2.24,2.24v9.25a2.24,2.24,0,0,0,2.24,2.24H23.84a2.24,2.24,0,0,0,2.24-2.24V12.18A2.24,2.24,0,0,0,23.84,9.94Zm-1.66,1.94-5.37,3.74-5.38-3.74Zm1.66,9.86H9.77a.31.31,0,0,1-.3-.31V12.87L16.81,18l7.34-5.12v8.56A.32.32,0,0,1,23.84,21.74Z"
    />
  </svg>
);

export default SVG;

//<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.61 33.61"><title>ico_mail</title><g id="87779e26-47c5-4409-bee8-63b57ac726ee" data-name="Calque 2"><g id="843d70cf-d09c-43d4-b03d-28be5b10a98a" data-name="Calque 1"><path d="M16.81,33.61a16.81,16.81,0,1,1,16.8-16.8A16.83,16.83,0,0,1,16.81,33.61Zm0-32.15A15.35,15.35,0,1,0,32.15,16.81,15.36,15.36,0,0,0,16.81,1.46Z"/><path d="M23.84,9.94H9.77a2.25,2.25,0,0,0-2.24,2.24v9.25a2.24,2.24,0,0,0,2.24,2.24H23.84a2.24,2.24,0,0,0,2.24-2.24V12.18A2.24,2.24,0,0,0,23.84,9.94Zm-1.66,1.94-5.37,3.74-5.38-3.74Zm1.66,9.86H9.77a.31.31,0,0,1-.3-.31V12.87L16.81,18l7.34-5.12v8.56A.32.32,0,0,1,23.84,21.74Z"/></g></g></svg>