import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import App from './App';
if ((!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') && !process.env.REACT_APP_NODE_ENV) {
  if (window.location.protocol !== 'https:') {
    window.location = 'https:' + window.location.href.substring(window.location.protocol.length)
  }
}

const updateHandler = (registration) => {
  if (window.confirm("Update available. Do you want to reload?")){
    window.location.reload();
  }
};

ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: updateHandler,
});
