import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 33.61 33.61"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
        fill={fill}
        d="M16.81,33.61a16.81,16.81,0,1,1,16.8-16.8A16.83,16.83,0,0,1,16.81,33.61Zm0-32.15A15.35,15.35,0,1,0,32.15,16.81,15.36,15.36,0,0,0,16.81,1.462"
    />
    <path
      fill={fill}
      d="M26.35,10.78a7.7,7.7,0,0,1-1.94.56c.31-.05.77-.62,1-.85A3.57,3.57,0,0,0,26,9.31a.08.08,0,0,0,0-.1.09.09,0,0,0-.1,0,10.1,10.1,0,0,1-2.31.88.15.15,0,0,1-.16,0,2,2,0,0,0-.2-.21,4.15,4.15,0,0,0-1.12-.68,4,4,0,0,0-1.72-.28,4.19,4.19,0,0,0-1.62.46,4.14,4.14,0,0,0-1.31,1.07A4.09,4.09,0,0,0,16.68,12a4.42,4.42,0,0,0,0,1.66c0,.09,0,.11-.08.09a12.3,12.3,0,0,1-7.93-4c-.09-.11-.14-.11-.22,0a4,4,0,0,0,.69,4.75c.15.14.31.29.49.43a4.24,4.24,0,0,1-1.53-.43c-.1-.06-.14,0-.15.08a2.3,2.3,0,0,0,0,.45,4.06,4.06,0,0,0,2.51,3.24,2.32,2.32,0,0,0,.51.16,4.79,4.79,0,0,1-1.5,0c-.11,0-.15,0-.11.14a4.2,4.2,0,0,0,3.15,2.64c.14,0,.29,0,.43.06,0,0,0,0,0,0a5,5,0,0,1-2.15,1.14,7.74,7.74,0,0,1-3.27.42c-.17,0-.21,0-.26,0s0,.07,0,.11c.22.15.45.28.68.41a10.87,10.87,0,0,0,2.19.87,11.62,11.62,0,0,0,11.3-2.64A11.85,11.85,0,0,0,24.56,13c0-.13.15-.2.24-.26a8,8,0,0,0,1.53-1.6.47.47,0,0,0,.09-.31v0S26.43,10.75,26.35,10.78Z"
    />
  </svg>
);

export default SVG;

//<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.61 33.61"><defs><style>.\31 1c3b100-8c9a-406d-85fa-735337b6cbed{fill-rule:evenodd;}</style></defs><title>ico_twitter</title><g id="6c8aab52-371f-4650-918b-817bb06372ec" data-name="Calque 2"><g id="baa088f9-bf21-4af7-a95f-b4cbeb5c404a" data-name="Calque 1"><path d="M26.35,10.78a7.7,7.7,0,0,1-1.94.56c.31-.05.77-.62,1-.85A3.57,3.57,0,0,0,26,9.31a.08.08,0,0,0,0-.1.09.09,0,0,0-.1,0,10.1,10.1,0,0,1-2.31.88.15.15,0,0,1-.16,0,2,2,0,0,0-.2-.21,4.15,4.15,0,0,0-1.12-.68,4,4,0,0,0-1.72-.28,4.19,4.19,0,0,0-1.62.46,4.14,4.14,0,0,0-1.31,1.07A4.09,4.09,0,0,0,16.68,12a4.42,4.42,0,0,0,0,1.66c0,.09,0,.11-.08.09a12.3,12.3,0,0,1-7.93-4c-.09-.11-.14-.11-.22,0a4,4,0,0,0,.69,4.75c.15.14.31.29.49.43a4.24,4.24,0,0,1-1.53-.43c-.1-.06-.14,0-.15.08a2.3,2.3,0,0,0,0,.45,4.06,4.06,0,0,0,2.51,3.24,2.32,2.32,0,0,0,.51.16,4.79,4.79,0,0,1-1.5,0c-.11,0-.15,0-.11.14a4.2,4.2,0,0,0,3.15,2.64c.14,0,.29,0,.43.06,0,0,0,0,0,0a5,5,0,0,1-2.15,1.14,7.74,7.74,0,0,1-3.27.42c-.17,0-.21,0-.26,0s0,.07,0,.11c.22.15.45.28.68.41a10.87,10.87,0,0,0,2.19.87,11.62,11.62,0,0,0,11.3-2.64A11.85,11.85,0,0,0,24.56,13c0-.13.15-.2.24-.26a8,8,0,0,0,1.53-1.6.47.47,0,0,0,.09-.31v0S26.43,10.75,26.35,10.78Z"/><path class="11c3b100-8c9a-406d-85fa-735337b6cbed" d="M12.9,24.66Z"/><path class="11c3b100-8c9a-406d-85fa-735337b6cbed" d="M12.9,24.66Z"/><path class="11c3b100-8c9a-406d-85fa-735337b6cbed" d="M13.55,24.67s.34,0,0,0C13.55,24.66,13.59,24.67,13.55,24.67Z"/><path class="11c3b100-8c9a-406d-85fa-735337b6cbed" d="M20.35,8.91a.06.06,0,0,1-.08,0Z"/><path d="M16.81,33.61a16.81,16.81,0,1,1,16.8-16.8A16.83,16.83,0,0,1,16.81,33.61Zm0-32.15A15.35,15.35,0,1,0,32.15,16.81,15.36,15.36,0,0,0,16.81,1.46Z"/></g></g></svg>