
import React from 'react'
import { CSSTransition } from 'react-transition-group';

class LoadPage extends React.Component {
    render() {
        var timeout = 2000
        if(/bot|googlebot|crawler|spider|robot|crawling|Chrome-Lighthouse/i.test(navigator.userAgent)){
            timeout = 0
            return null
        }
        
        return (<>
            {this.props.notreset && (
                <div className="content-loader">
                    <div className="container-absolute">
                        <div className="title text-white">
                            <div className="m-maverick"><span>|</span><span className="color-primary">|</span><span>|</span><span>|</span></div>averick</div>
                        <div className="title-2 text-white">Paris</div>
                        <div className="subtitle text-primary">\ˈmæv.əɹ.ɪk\ n.m</div>
                    </div>
                </div>
            )}   
            {!this.props.notreset && ( 
                <CSSTransition appear={this.props.load} in={this.props.load} classNames="loader" timeout={timeout}>
                    <div className="content-loader">
                        <div className="container-absolute">
                            <div className="title text-white">
                                <div className="m-maverick"><span>|</span><span className="color-primary">|</span><span>|</span><span>|</span></div>averick</div>
                            <div className="title-2 text-white">Paris</div>
                            <div className="subtitle text-primary">\ˈmæv.əɹ.ɪk\ n.m</div>
                        </div>
                    </div>
                </CSSTransition>
            )}
        </>)
    }
}

export default LoadPage;