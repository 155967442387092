import React from "react";
import Cookies from 'js-cookie';
import Icon from "../assets/icons";
import useIosInstallPrompt from '../app/shared/hooks/useIosInstallPrompt';
import useWebInstallPrompt from '../app/shared/hooks/useWebInstallPrompt';

const InstallPWA = (props) => {
    const [iosInstallPrompt,isIOSShowNotice, handleIOSInstallDeclined] = useIosInstallPrompt();
    const [webInstallPrompt,showDiscoverApp, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

    if(!Cookies.get('banner-cookie')){
        return null
    }

    if(/bot|googlebot|crawler|spider|robot|crawling|Chrome-Lighthouse/i.test(navigator.userAgent)){
        return null
    }

    if(props.panel === 'menu'){
        return (<>
            {isIOSShowNotice && (<>
                Cliquez sur <Icon name="install-app-ios"/> puis choisissez "Sur&nbsp;l'écran&nbsp;d'accueil" pour découvrir l'app
            </>)}
            {showDiscoverApp && !isIOSShowNotice && (<>
                <button
                    className="btn btn-primary text-center"
                    aria-label="Découvrir l'app"
                    title="Découvrir l'app"
                    onClick={handleWebInstallAccepted}
                    >
                    Découvrir l'app
                </button>
            </>)}
        </>)
    }

    if (!iosInstallPrompt && !webInstallPrompt) {
        return null;
    }
  
    return (
        <div className="bg-dark col-12 p-3 d-md-none banner-install-app">
            {iosInstallPrompt && (<>
                <div className="small text-primary p-2 text-right" onClick={handleIOSInstallDeclined}>Plus tard</div>
                <p>Le site Maverick.Paris s'utilise aussi en application.</p>   
                Cliquez sur <Icon name="install-app-ios"/> puis choisissez "Sur l'écran d'accueil"
            </>)}
            {webInstallPrompt && (<>
                <div className="small text-primary p-2 text-right" onClick={handleWebInstallDeclined}>Plus tard</div>
                <p>Le site Maverick.Paris s'utilise aussi en application.</p>
                <button
                    className="btn btn-primary text-center"
                    id="setup_button"
                    aria-label="Découvrir l'app"
                    title="Découvrir l'app"
                    onClick={handleWebInstallAccepted}
                    >
                    Découvrir l'application
                </button>
            </>)}
        </div>
    );
};

export default InstallPWA;
