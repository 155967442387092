import React from "react";

const SVG = ({
  style = {},
  fill = "none",
  width = "30",
  height = "30",
  className = "",
  viewBox = "0 0 29 37"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <polyline fill={fill} stroke="#DF01FF" strokeWidth="2" strokeMiterlimit="10" points="7.6,7 14.6,2 21.6,7 "/>
    <line fill={fill} stroke="#DF01FF" strokeWidth="2" strokeMiterlimit="10" x1="14.6" y1="2" x2="14.6" y2="24"/>
    <path fill={fill} stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M17.7,12h7.4c0.6,0,1,0.4,1,1v21c0,0.6-0.4,1-1,1h-21c-0.6,0-1-0.4-1-1V13c0-0.6,0.4-1,1-1h7.3"/>
  </svg>
);

export default SVG;