import React from "react";
import LeftArrow from "./Arrow";
import Facebook from "./Facebook";
import Mail from "./Mail";
import Phone from "./Phone";
import Twitter from "./Twitter";
import Linkedin from "./Linkedin";
import Filter from "./Filter";
import IosPartage from "./IosPartage";

const Icon = props => {
  switch (props.name) {
    case "filter":
      return <Filter {...props} />;
    case "left-arrow":
      return <LeftArrow {...props} />;
    case "share-fb":
      return <Facebook {...props} />;
    case "contact-mail":
      return <Mail {...props} />;
    case "contact-phone":
      return <Phone {...props} />;
    case "contact-twitter":
      return <Twitter {...props} />;
    case "contact-linkedin":
      return <Linkedin {...props} />;
    case "install-app-ios":
      return <IosPartage {...props} />;
    default:
      return;
  }
};

export default Icon;
