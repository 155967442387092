import React from "react";

const SVG = ({
  style = {},
  fill = "#000",
  width = "100%",
  height = "100%",
  className = "",
  viewBox = "0 0 33.6 33.6"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M16.8,33.6A16.8,16.8,0,1,1,33.6,16.8,16.77,16.77,0,0,1,16.8,33.6Zm0-32.1A15.3,15.3,0,1,0,32.1,16.8,15.3,15.3,0,0,0,16.8,1.5Z"
    />
  </svg>
);

export default SVG;

//<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.6 33.6">
//    <title>ico_fb</title><g id="732142c8-97a6-4dbf-bdfc-c6bdaf36bfc8" data-name="Calque 2"><g id="c06d5f59-bf2f-4623-a708-ef1e1775405f" data-name="Calque 1"><path d="M14.3,16.9v9.6a.22.22,0,0,0,.2.2h3.6a.22.22,0,0,0,.2-.2V16.8h2.6a.22.22,0,0,0,.2-.2l.2-2.9c0-.1-.1-.3-.2-.3H18.3V11.3a.9.9,0,0,1,.9-.9h2a.22.22,0,0,0,.2-.2v-3a.22.22,0,0,0-.2-.2H17.8a3.59,3.59,0,0,0-3.6,3.6v2.8H12.4a.22.22,0,0,0-.2.2v2.9a.22.22,0,0,0,.2.2h1.8v.2Z"/><path d="M16.8,33.6A16.8,16.8,0,1,1,33.6,16.8,16.77,16.77,0,0,1,16.8,33.6Zm0-32.1A15.3,15.3,0,1,0,32.1,16.8,15.3,15.3,0,0,0,16.8,1.5Z"/></g></g></svg>