import React from 'react'
import Icon from "../assets/icons";

class ContactIcon extends React.Component {
    // Using ES6 class syntax
    constructor(props) {
       super(props);

       this.state = {
            hovered:false,
            hoveredScd:false,
            hoveredLkn:false,
            hoveredTwi: false
        }
        this.handleMouse = this.handleMouse.bind(this)
        this.handleMouseScd = this.handleMouseScd.bind(this)
        this.handleMouseLkn = this.handleMouseLkn.bind(this)
        this.handleMouseTwi = this.handleMouseTwi.bind(this)
    }

    handleMouse() {
        this.setState({
            hovered: !this.state.hovered
        })
    }

    handleMouseScd() {
        this.setState({
            hoveredScd: !this.state.hoveredScd
        })
    }

    handleMouseLkn() {
        this.setState({
            hoveredLkn: !this.state.hoveredLkn
        })
    }
    
    handleMouseTwi() {
        this.setState({
            hoveredTwi: !this.state.hoveredTwi
        })
    }
    
    render() {
        var rendered = <div className="row text-center justify-content-center p-2">
            <div className="btn-share mr-2" onMouseEnter={this.handleMouse} onMouseLeave={this.handleMouse}><a href={"mailto:contact@maverick.paris"}><Icon name="contact-mail" width={35} height={35} fill={this.state.hovered ? '#DF01FF' : '#fff'} /></a></div>
            <div className="btn-share ml-2" onMouseEnter={this.handleMouseScd} onMouseLeave={this.handleMouseScd}><a href={"tel:+33658392167"}><Icon name="contact-phone" width={35} height={35} fill={this.state.hoveredScd ? '#DF01FF' : '#fff'} /></a></div>
        </div>
        if(this.props.menu){
            var size=25
            if(this.props.size){
                size=this.props.size
            }
            rendered = <div className="row text-center justify-content-center p-2">
                <div className="btn-share mr-2" onMouseEnter={this.handleMouseLkn} onMouseLeave={this.handleMouseLkn}><a href="https://www.linkedin.com/company/agence-maverick/" target="_blank" rel="noopener noreferrer"><Icon name="contact-linkedin" width={size} height={size} fill={this.state.hoveredLkn ? '#DF01FF' : '#fff'} /></a></div>
                <div className="btn-share ml-2 mr-2" onMouseEnter={this.handleMouseTwi} onMouseLeave={this.handleMouseTwi}><a href="https://twitter.com/ParisMaverick" target="_blank" rel="noopener noreferrer"><Icon name="contact-twitter" width={size} height={size} fill={this.state.hoveredTwi ? '#DF01FF' : '#fff'} /></a></div>
                <div className="btn-share ml-2 mr-2" onMouseEnter={this.handleMouse} onMouseLeave={this.handleMouse}><a href={"mailto:contact@maverick.paris"}><Icon name="contact-mail" width={size} height={size} fill={this.state.hovered ? '#DF01FF' : '#fff'} /></a></div>
                <div className="btn-share ml-2" onMouseEnter={this.handleMouseScd} onMouseLeave={this.handleMouseScd}><a href={"tel:+33658392167"}><Icon name="contact-phone" width={size} height={size} fill={this.state.hoveredScd ? '#DF01FF' : '#fff'} /></a></div>
            </div>
        }
        return (
            rendered
        ); 
    }
}

export default ContactIcon;